import React from 'react';
import styled, { StyleSheetManager, css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import { ImageCore, LanguagePicker, LinkWrapper, Text } from '@components';
import { query } from '@shared/style-helpers';
import { usePlansValuesContext, useTimerContext } from '@context';

import atlasLogo from '@images/logos/atlas-logo.svg';
import atlasLogoWhite from '@images/logos/atlas-logo-white.svg';

const VisibilityWrapper = styled.div`
  width: 100%;
  z-index: ${({ isHidden }) => (isHidden ? 0 : 20)};
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  transition-property: opacity;
  transition-duration: ${({ transitionDuration }) => transitionDuration || '200ms'};

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      top: 0;
    `}

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
    `}

  ${({ hiddenMobile }) =>
    hiddenMobile &&
    css`
      @media ${query.maxTabletSmall} {
        display: none;
      }
    `}
    
  ${({ hiddenTablet }) =>
    hiddenTablet &&
    css`
      @media ${query.minTabletLarge} {
        display: none;
      }
    `}
`;

const ButtonVisibilityWrapper = styled(Box)`
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

const TimerWrapper = styled.span`
  direction: ltr;
`;

const TimerBar = ({
  hiddenMobile,
  hiddenTablet,
  sticky,
  fixed,
  isHidden,
  transitionDuration,
  backgroundColor,
  color,
  data,
  showLanguagePicker = true,
  showTimer = true,
}) => {
  const timer = useTimerContext();
  const timeLeft = `${timer?.hours} : ${timer?.minutes} : ${timer?.seconds}`;

  const theme = useTheme();

  const { t } = useTranslation();
  const {
    suggestedPlan: { discountPercentage },
  } = usePlansValuesContext();

  const { button } = data({ discountPercentage, t });

  return (
    <VisibilityWrapper
      hiddenMobile={hiddenMobile}
      hiddenTablet={hiddenTablet}
      sticky={sticky}
      fixed={fixed}
      isHidden={isHidden}
      transitionDuration={transitionDuration}
    >
      <Box
        minHeight={{ _: 54, md: 66, lg: 82 }}
        data-section-id="DealsTimerBar"
        pl={{ _: 8, sm: 24, lg: 40 }}
        pr={{ md: 24, lg: 40 }}
        py={{ _: 8, lg: 16 }}
        width="100%"
        backgroundColor={backgroundColor || 'backgroundColor'}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="100%"
          maxWidth={1200}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ImageCore
            src={theme.variants?.timerBarLogo === 'white' ? atlasLogoWhite : atlasLogo}
            alt="Atlas VPN"
            mt={-6}
            height={25}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent={{ _: 'space-around', sm: 'space-between' }}
            width="auto"
            flexWrap="wrap"
          >
            {button && (
              <ButtonVisibilityWrapper
                display={{ _: 'none', md: 'block' }}
                minWidth={{ _: 152, sm: 195 }}
                my={3}
                isHidden={isHidden}
              >
                <LinkWrapper link={button.link} withParams>
                  <Button type="button" fullWidth {...button.buttonProps}>
                    {button.title}
                  </Button>
                </LinkWrapper>
              </ButtonVisibilityWrapper>
            )}

            {showTimer && (
              <Box ml={{ sm: 24, lg: 50 }}>
                <StyleSheetManager stylisPlugins={[]}>
                  <TimerWrapper>
                    <Text.Subheading2
                      fontSize={{ _: 18, lg: 22 }}
                      color={color || 'fontDefault'}
                      minWidth={{ _: 101, lg: 124 }}
                      my={3}
                    >
                      {timeLeft}
                    </Text.Subheading2>
                  </TimerWrapper>
                </StyleSheetManager>
              </Box>
            )}
            {showLanguagePicker && (
              <LanguagePicker
                wrapperProps={{ ml: { md: 32, lg: 48 }, mr: { md: 16, lg: 0 } }}
                openedWrapperProps={{ top: 46 }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </VisibilityWrapper>
  );
};

export default TimerBar;
